.formulario {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
}

.formulario .form-group {
    display: flex;
    flex-direction: column;
    margin: 15px;
}

.formulario .check-group {
    margin-top: 12px;
}

.formulario .form-group textarea {
    margin: 0 auto;
}