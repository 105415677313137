.article-items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.articulo-item {
    background-color: #f1eaea;
    padding: 20px;
    margin: 15px;
    border-radius: 12px;
    border-bottom: none;
    font-size: 10px;
    box-shadow: 0px 0px 5px gray;
    width: 300px;
    text-align: center;
}

.boton-delete {
    padding: 6px;
    
}

.form-article {
    width: 300px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.form-article div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}

.form-article textarea {
    max-width: 80px;
    margin: 20px;
}

.form-article button {
    padding: 5px;
    width: 80px;
    margin: 0;
    margin-bottom: 20px;
}