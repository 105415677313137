ul {
    margin: 0;
    padding: 0;
}

.header {
    background-color: gray;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    width: 200px;
    margin-right: 20px;
}

.logo img {
    width: 90px;
    margin: 25px;
}

.logo h3 {
    width: 200px;
    color: white;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
}

.header.logo {
    flex-wrap: 2;
}

.header .menu ul {
    flex-grow: 1;
    display: flex;
}

.header .menu ul li {
    list-style: none;
}

.header .menu ul li a {
    text-decoration: none;
    color: white;
    padding: 20px;
    margin: 15px;
    font-size: 18px;
    transition: all 300ms;
}

.active,
.header .menu ul li a:hover {
    background-color: blue;
    border-radius: 5px;
    color: yellow;
}

.toggle {
    display: none;
    margin: 15px;
    flex-direction: column;

}

.toggle span {
    width: 50px;
    height: 6px;
    background-color: white;
    margin-top: 4px;
    border-radius: 5px;
    transform-origin: (5px, 0px);
    transition: all 300ms;
}

.toggle:hover span {
    background-color: blue;
    cursor: pointer;
}

.menu.open {
    transform: translateX(0);
    transition: all 300ms;
}

.toggle.open>span {
    transform: rotate(45deg) translate(0px, 0px);
}

.toggle.open>span:nth-child(2) {
    display: none;
}

.toggle.open>span:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

.toggle p {
    color: white;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 6px;
}

.content-aside{
    width: 100%;
    background-color: blue;
    padding: 20px;
    box-sizing: border-box;
}

.articulos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.articulos .name {
    color: white;
    margin: 0;
    font-size: 60px;
}

.articulos .contenido-aside {
    color: white;
    width: 300px;
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
}

.schedule {
    width: 100%;
    background-color: gray;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
}

.schedule h2 {
    padding: 15px;
    margin: 0;
    border-right: 4px solid blue;
    text-align: center;
    width: 500px;
}

.schedule .hour {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.schedule .hour p {
    margin: 10px;
    padding: 0;
}

.vehiculos {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.vehiculos h1 {
    border-right: 4px solid yellow;
    padding: 20px;
    text-align: center;
}

.vehiculos h2 {
    font-size: 20px;
    color: gray;
    width: 300px;
    text-align: center;
}

.car {
    width: 100%;
}

.footer {
    background-color: gray;
    color: white;
    width: 100%;
    height: 150px;
    padding: 30px;
    box-sizing: border-box;
}

.footer footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    
}

.footer p {
    text-align: center;
    font-size: 20px;
}

.footer h3 {
    margin: 0;
    padding: 0;
    font-size: 20px;
    text-align: center;
}

.car {
    display: block;
}

.whats {
    display: none;
    width: 80px;
    height: 80px;
    background-color: white;
    position: fixed;
    border-radius: 50%;
    text-align: center;
    box-sizing: border-box;
    top: 85%;
    right: 8%;
}

.whats .item-whats {
    font-size: 80px;
    color: rgb(22, 180, 22);
    position: relative;
    top: 1px
    
}

.whats .item-whats:hover {
    color: blue;
    cursor: pointer;
}



@media (max-width: 797px) {
    .header {
        display: flex;
        flex-direction: column;
    }

    .logo {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .toggle {
        display: flex;
    }

    .menu {
        width: 100%;
        background-color: gray;
        position: absolute;
        top: 160px;
        transform: translateX(-100%);
        transition: all 300ms ease-out;
        z-index: 4;
        opacity: 0.9;

    }

    .header .menu ul li {
        width: 100%;
        margin: 20px;
    }

    .header .menu ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .header .menu ul li a {
        padding: 15px;
        margin: 30px;
    }

    .active,
    .header .menu ul li a:hover {
        background-color: gray;
        border-bottom: 2px solid blue;
    }

    .schedule {
        display: flex;
        flex-direction: column;
    }

    .schedule h2 {
        border-bottom: 4px solid blue;
        border-right: none;
        width: 200px;
    }

    .vehiculos {
        display: flex;
        flex-direction: column;
    }

    .vehiculos h1 {
        border-right: none;
        border-bottom: 4px solid yellow;
    }

    .footer footer {
        display: flex;
        flex-direction: column;
    }

    .whats {
        display: block;
    }
}