.formulario {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.formulario .form-group {
    display: flex;
    flex-direction: column;
    margin: 15px;
}

.formulario .check-group {
    margin-top: 12px;
}