.portada {
    width: 100%;
    margin: 0;
    display: block;
}

.info {
    color: gray;
}

.container article {
    border-bottom: 2px solid rgb(209, 209, 28);;
}

.separator {
    width: 100%;
    background-color:rgb(236, 236, 40);
    height: 200px;
}

@media (max-width: 768px) {
    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}