.menu-encuestas {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.menu-encuestas a {
    text-decoration: none;
    padding: 20px;
    background-color: gray;
    color: white;
    border-radius: 5px;
    margin: 20px;
}

.menu-encuestas a:hover {
    background-color: blue;
    transition: all 300ms;
}

.container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

@media (max-width: 768px) {

    .menu-encuestas {
        display: flex;
        flex-direction: column;
        margin: 20px;
    }

    .menu-encuestas li {
        margin: 30px;
    }
}