.teach {
    width: 100%;
    height: 500px;
    background-image: url("../../assets/img/cars/show.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
}

.info {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content:space-around;
    align-items: center;
}

.info ul {
    display: flex;
    margin: 0;
    padding: 0;
    text-align: center;
}

.info ul li {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 60px;
    flex-wrap: 1;
}

.info ul li a {
    text-decoration: none;
    padding: 20px;
    border: 2px solid yellow;
    color: white;
    text-shadow: 0px 0px 4px black;
    border-radius: 5px;
    transition: all 300ms;
}

.info ul li a:hover {
    background-color: rgb(209, 209, 28);
}

.info .slogan {
    width: 350px;
    color: white;
    margin-left: 20px;
    font-size: 20px;
    text-align: center;
    text-shadow: 0px 0px 5px black;
    opacity: 0.6;
}

.separator {
    width: 100%;
    background-color:rgb(236, 236, 40);
    height: 200px;
    text-align: center;
    color: white;
    padding-top: 60px;
    box-sizing: border-box;
}

.separator h1 {
    margin-top: 16px;
    padding: 0;
    text-shadow: 0px 0px 4px black;
    font-size: 40px;
}

section article {
    border-bottom: 2px solid rgb(209, 209, 28);
}

.section {
    margin: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: black;
    text-align: center;
}

.section .title {
    font-weight: bold;
    font-size: 40px;
    margin: 15px;
    flex-grow: 1;
    width: 500px;
}

.section .description {
    width: 40%;
    font-weight: 200;
    font-size: 20px;
    color: gray;
    flex-grow: 2;
}

.section .title .item {
    font-size: 50px;
    color: blue;
}

@media (max-width: 768px) {

    .info {
        display: flex;
        height: 300px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .info ul {
        display: flex;
        flex-direction: column;
    }

    .info ul li {
        margin: 30px;
    }

    .section {
        display: flex;
        flex-direction: column;
    }

    .section .title {
        width: 300px;
    }

    .section .description {
        width: 80%;
    }
}