.portada {
    width: 100%;
    display: block;
}

.contacto {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;
}

.contact {
    display: flex;
    flex-direction: column;
    width: 450px;
    margin: 20px;
}

input[type="text"],
input[type="email"],
input[type='password'],
textarea {
    width: 350px;
    max-width: 350px;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
}

input[type="submit"],
button {
    width: 350px;
    padding: 15px;
    background-color: gray;
    color: white;
    margin: 15px;
    border: none;
    border-radius: 5px;
    transition: all 300ms;
}

input[type="submit"]:hover,
button:hover {
    background-color: blue;
    cursor: pointer;
}

.social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 40px;
}

.social .items a {
    margin: 0;
    padding: 0;
    flex-grow: 1;
}

.social .items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 400px;
    text-align: center;
}

.social .item {
    font-size: 80px;
    color: gray;
    margin: 20px;
    flex-grow: 1;
}

.social .item:hover {
    color: blue;
    cursor: pointer;
}

@media (max-width: 768px) {

    .contacto {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .contact {
        display: flex;
        flex-direction: column;
        width: 85%;
    }

    input[type="text"],
    input[type="email"],
    input[type='password'],
    textarea {
        width: 85%;
        max-width: 85%;
        padding: 20px;
        border-radius: 5px;
        margin-top: 20px;
        text-align: center;
    }

    input[type="submit"],
    button {
        width: 75%;
        padding: 20px;
        background-color: gray;
        color: white;
        margin-left: 40px;
        border-radius: 5px;
        transition: all 300ms;
        text-align: center;
    }

    .social .items {
        width: 300px;
    }
}